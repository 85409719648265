<template>
  <div class="show-api-key__popup-root">
    <div class="show-api-key__popup-bg" @click="closeShowApiKeyPopup"/>
    <div class="show-api-key__popup">

      <div class="show-api-key__title"> {{ $t('showApiKeyPopup.title') }} </div>

      <div class="show-api-key__input-block">
        <label class="show-api-key__input-label"> {{ $t('apiKeyChangePopup.passedPasswordInput') }} </label>
        <div class="show-api-key__input">
          <input
              v-model="userPassword"
              :type="passedPasswordType"
              :placeholder="$t('apiKeyChangePopup.passedPasswordInput')"
              class="show-api-key__input"
          />
          <img
              v-if="passedPasswordIcon"
              @click="toggleInputType('passedPassword')"
              class="show-api-key__input-image"
              src="~/assets/img/inputs/passlock-icon.svg"
          />
          <img
              v-if="!passedPasswordIcon"
              @click="toggleInputType('passedPassword')"
              class="show-api-key__input-image"
              src="~/assets/img/inputs/passUnlock-icon.svg"
          />
        </div>
      </div>
      <button
          v-if="!isLoading"
          class="show-api-key__submit-button btn primary"
          @click="getApiKey"
      >
        <span>{{ $t('button.show') }}</span>
      </button>
      <button
        v-if="isLoading"
        class="show-api-key__submit-button btn primary"
        :disabled="true"
      >
        <DefaultLoader />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {showApiKeyPopupStore, showApiKeyPopupStore as store } from "~/stores/showApiKey-popup-store";
import {useUserAuth} from "~/composables/user/useUserAuth";
import {successPopupStore} from "~/stores/success-popup-store";

import type {SuccessPopupOptions} from "~/stores/success-popup-store"
const { $i18n, $toast } = useNuxtApp();
const { closeShowApiKeyPopup } = showApiKeyPopupStore();
const { openSuccessPopup } = successPopupStore();

const {
  currentUser,
  updateUserMe,
  userGetApiKey
} = useUserAuth();

const DefaultLoader = defineAsyncComponent(() => import('~/components/widgets/loaders/DefaultLoader.vue'))

const userPassword = ref(null);

const isLoading = ref(false)

async function getApiKey () {
  isLoading.value = true;
  let body: any = {
    password: userPassword.value
  }

  const res = await userGetApiKey(body);

  if (res && res.status === "ok") {
    // обходим ошибку типов, т.к data объект вместо массива
    const _res = Object.entries(res.data).map((item) =>
      ( { [item[0]]: item[1] } )
    )
    closeShowApiKeyPopup();

    const successOptions: SuccessPopupOptions = {
      title: String($i18n.t("apiKeyChangePopup.thisApi") ),
      message: String(_res?.[0]?.apiKey),
    }

    isLoading.value = false;

    openSuccessPopup({ ...successOptions } );
  }
  if (res && res.status === 'error') {
    isLoading.value = false;
    $toast.error(res.message)
  }
}

const passedPasswordType = ref('password')

const toggleInputType = function(inputName: any) {
  if (inputName === 'passedPassword' && passedPasswordType.value === 'password') {
    return passedPasswordType.value = 'text';
  }
  if (inputName === 'passedPassword' && passedPasswordType.value === 'text') {
    return passedPasswordType.value = 'password';
  }
}

const passedPasswordIcon = computed(() => {
  return passedPasswordType.value === 'password';
})
</script>

<style scoped>
.show-api-key__popup-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 997;
  align-items: center;
  padding: 15% 0;
}
.show-api-key__popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  transition: 0.5s all;
}
.show-api-key__popup {
  background: white;
  width: 30%;
  border-radius: 24px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  transition: 0.5s all;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px #00000040;
  gap: 30px;
}
.show-api-key__title {
  color: #333333;
  font-size: 40px;
  font-weight: 600;
  line-height: 46.88px;
  text-align: center;
  padding-bottom: 2px;
}
.show-api-key__input-block {
  display: flex;
  flex-direction: column;
}
.show-api-key__input-label {
  color: #666666;
  font-size: 18px;
  line-height: 21.09px;
  font-weight: 600;
  text-align: center;
}
.show-api-key__input {
  position: relative;
  border-radius: 12px;
}
.show-api-key__submit-button {
  display: flex;
  width: 100%;
}
.show-api-key__input-image {
  position: absolute;
  top: 30%;
  right: 2%;
  opacity: 30%;
  cursor: pointer;
}
@media screen and (max-width: 1600px) {
  .show-api-key__popup {
    width: 50%;
  }
}
@media screen and (max-width: 859px) {
  .show-api-key__popup {
    width: 70%;
  }
}
@media screen and (max-width: 640px) {
  .show-api-key__popup {
    width: 90%;
  }
  .show-api-key__title {
    font-size: 25px;
    line-height: 27.38px;
    padding: 0;
  }
  .show-api-key__input-block {
    align-items: flex-start;
  }
  .show-api-key__input-label {
    font-size: 16px;
    line-height: 18.75px;
  }
}
</style>
